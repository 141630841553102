(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/banner-slider/views/index.js') >= 0) return;  svs.modules.push('/widgets/banner-slider/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.banner_slider=_svs.widget.banner_slider||{};
_svs.widget.banner_slider.templates=_svs.widget.banner_slider.templates||{};
svs.widget.banner_slider.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldCenter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "         data-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"widgetId") || (depth0 != null ? lookupProperty(depth0,"widgetId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widgetId","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":30}}}) : helper)))
    + "\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"childElements") || (depth0 != null ? lookupProperty(depth0,"childElements") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childElements","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          class=\"widget-banner-slider widget-banner-slider-centered font-wrapper widget-full-height widget-full-width "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"background") || (depth0 != null ? lookupProperty(depth0,"background") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"background","hash":{},"data":data,"loc":{"start":{"line":5,"column":118},"end":{"line":5,"column":132}}}) : helper)))
    + "\"\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          class=\"widget-banner-slider font-wrapper widget-full-height widget-full-width "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"background") || (depth0 != null ? lookupProperty(depth0,"background") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"background","hash":{},"data":data,"loc":{"start":{"line":7,"column":88},"end":{"line":7,"column":102}}}) : helper)))
    + "\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":9}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['widget-banner_slider-index'] = svs.widget.banner_slider.templates.index;
})(svs, Handlebars);


 })(window);